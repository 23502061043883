/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import SmartyStreetsSDK from 'smartystreets-javascript-sdk';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';

export const ADDRESS_REFRESH_DELAY = 500; // search-debounce
export const ADDRESS_MAX_SUGGESSTION = 5; // Limit suggestions if needed in future
export const ADDRSS_SOURCE_ONLY = 'all'; // Include secondary addresses if available - others+PostalAddress

/** @namespace Seedsman/Util/SmartyAddress/Index */
export class SmartyStreetsAddressContainer extends Component {
    static propTypes = {
        onAutoComplete: PropTypes.func.isRequired,
        handleSmartAddressToggle: PropTypes.func.isRequired
    };

    suggestionRef = createRef();

    __construct(props) {
        super.__construct(props);
        this.state = {
            addressInput: '',
            suggestions: []
        };

        this.debounceTimeout = null;
        const SmartyStreetsCore = SmartyStreetsSDK.core;
        const credentials = new SmartyStreetsCore.SharedCredentials(process.env.REACT_APP_SMARTY_EMBEDDED_KEY);
        const clientBuilder = new SmartyStreetsCore.ClientBuilder(credentials).withLicenses(['us-autocomplete-pro-cloud']);
        this.client = clientBuilder.buildUsAutocompleteProClient();
    }

    fetchAddressSuggestions = async (address) => {
        const lookup = new SmartyStreetsSDK.usAutocompletePro.Lookup(address);
        lookup.maxResults = ADDRESS_MAX_SUGGESSTION;
        lookup.source = ADDRSS_SOURCE_ONLY;

        try {
            const response = await this.client.send(lookup);
            this.setState({ suggestions: response.result || [] });
        } catch (error) {
            console.error('Error fetching address suggestions:', error);
        }
    };

    handleSearch = (e) => {
        if (this.suggestionRef.current) {
            this.suggestionRef.current.style.display = 'block';
        }
        const { value } = e.target;
        this.setState({ addressInput: value });

        if (!value) {
            this.suggestionRef.current.style.display = 'none';
        }

        clearTimeout(this.debounceTimeout);

        this.debounceTimeout = setTimeout(() => {
            this.fetchAddressSuggestions(value);
        }, 500);
    };

    handleSelectSuggestion = (suggestion) => {
        const { onAutoComplete, handleSmartAddressToggle } = this.props;
        const formattedAddress = {
            street: [suggestion.streetLine],
            city: suggestion.city,
            state: suggestion.state,
            postcode: suggestion.zipcode,
            countryId: 'US'
        };

        handleSmartAddressToggle(false);
        onAutoComplete(formattedAddress);
        if (this.suggestionRef.current) {
            this.suggestionRef.current.style.display = 'none';
        }
    };

    render() {
        const { addressInput, suggestions } = this.state;

        return (
            <div block="SmartyStreetsAddress" elem="AddressSugestion">
                <Form>
                    <Field
                      type={ FIELD_TYPE.text }
                      value={ addressInput }
                      events={ {
                          onChange: this.handleSearch
                      } }
                      attr={ {
                          id: 'ship-address',
                          name: 'ship-address',
                          placeholder: 'Search Your Address here',
                          'aria-label': 'Search Your Address here'
                      } }
                      label="Start typing your address here..."
                    />
                </Form>

                <ul className="AddressSugestions-List-Wrapper" ref={ this.suggestionRef }>
                { suggestions.map((suggestion, index) => {
                    const {
                        streetLine, secondary, city, zipcode
                    } = suggestion;

                    return (
                    <li className="AddressSugestions-List" key={ index } onClick={ () => this.handleSelectSuggestion(suggestion) }>
                        { `${streetLine} ${secondary} ${city} ${zipcode}` }
                    </li>
                    );
                }) }
                </ul>
            </div>
        );
    }
}

export default SmartyStreetsAddressContainer;
