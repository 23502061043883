import {
    FieldForm as SourceFieldForm
} from 'SourceComponent/FieldForm/FieldForm.component';

// import { getPathnameFromURL } from 'Util/Url';
import './FieldForm.override.style';

/** @namespace Seedsman/Component/FieldForm/Component */
export class FieldFormComponent extends SourceFieldForm {
    renderFormBody() {
        const { isSmartAddressToggle, isSmartySearchEnabled } = this.props;

        return (
            <div
              block="FieldForm"
              elem={ (isSmartAddressToggle && isSmartySearchEnabled) ? 'Body CheckoutAddress' : 'Body' }
            >
                <div block="FieldForm" elem="Fields">
                    { this.fieldMap.map(this.renderSection.bind(this)) }
                </div>
                { this.renderActions() }
            </div>
        );
    }
}

export default FieldFormComponent;
